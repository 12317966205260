import React from "react";
import classes from "./Personal.module.scss";
import { NavLink } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faImages } from "@fortawesome/free-solid-svg-icons";

const Personal = () => {
  return (
    <div className={classes.Personal}>
      <div className={classes.Description}>
        <div className={classes.About}>
        Reportaż z uroczystości z których pragniecie mieć pamiątkę w postaci pięknych zdjęć. <br />
        Profesjonalne zdjęcia z każdej uroczystości. Jeżeli potrzebujecie fotografa który uwieczni wasze ważne wydarzenia z życia to dobrze trafiliście. 
        Otrzymacie piękne zdjęcia w formie papierowej w formie odbitek lub fotoksiążki. 
        Do tego otrzymacie film złożony z tych właśnie zdjęć tak by można było pokazać waszym znajomym na ekranie telewizora. 
        Przyjmuję zapisy na rok 2021 już dziś zarezerwuj termin. <br/><br/>
        Pracuje w całym kraju. Zapraszam do współpracy.
        </div>
        <div className={classes.ItemList}>
          <div className={classes.Item}>
            <h4>Fotografia chrztu</h4>
            <div>
              <div>
                Pakiet 1
                <br />
                <br />
                - reportaż z kościoła
                <br />
                - pozowane zdjęcia rodzinne (okolice kościoła)
                <br />
                - minimum 30 zdjęć w formie cyfrowej (webtransfer, dysk google)
                <br />
                - 30 zdjęć w formie papierowej 15x23
                <br />
                <br />
                <br />
                Cena: 500zł*
              </div>
              <div>
                Pakiet 2
                <br />
                <br />
                - reportaż z kościoła
                <br />
                - pozowane zdjęcia rodzinne (okolice kościoła lub sali)
                <br />
                - reportaż z przyjęcia (ok. 2 godz.)
                <br />
                - minimum 70 zdjęć w formie cyfrowej (Google dysk)
                <br />
                - standardowa książka foto-album A4 poziom 28 stron w cenie
                <br />
                <br />
                Cena: 700zł*
              </div>
              <div> 
                Dodatkowe opcje
                <br />
                - reportaż z przygotowań cena 150 zł
                <br />
                - standardowa książka foto-album format A4 28 stron poziom cena
                120 zł
                <br />
                - standardowa książka foto-album format A4 40 stron poziom cena
                170 zł
                <br />
                - standardowa książka foto-album format A3 28 stron poziom cena
                170 zł
                <br />
                - standardowa książka foto-album format A3 40 stron poziom cena
                240 zł
                <br />
                - foto-album premium (ze względu na cenę ustalana jest
                indywidualnie)
              </div>
            </div>
            <NavLink className={classes.Button} to={"/personal/baptism"} >
                Zobacz Zdjęcia <FontAwesomeIcon icon={faImages}/>
            </NavLink>
          </div>
          <div className={classes.Item}>
            <h4>Fotografia rodzinna</h4>
            <div>
              Zdjęcia pamiątkowe każdy powinien je mieć, gdyż z czasem nabierają
              wyjątkowego znaczenia.
              <br />
              Zachowajmy ulotne chwile na dłużej.
              <br />
              Fotografia rodzinna to sesja trwająca do 2 godzin w formie
              reportażu, sesji:
              <br />
              - spaceru po mieście, parku lub ogrodzie.
              <br />
              - w formie zabawy zwłaszcza gdy są z nami dzieci np: w
              piaskownicy, na rowerze, rolkach czy hulajnodze
              <br />
              - może być również w domu
              <br />
              - minimum 12 zdjęć w formie papierowej 15x23
              <br />
              <br />
              Cena: 350zł*
            </div>
            <NavLink className={classes.Button} to={"/personal/family"} >
                Zobacz Zdjęcia <FontAwesomeIcon icon={faImages}/>
            </NavLink>
          </div>
          <div>
           <b>* Cena na terenie Łodzi i okolic, poza ustalana indywidualnie.</b>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Personal;
