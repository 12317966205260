import React from 'react';
import classes from './Company.module.scss'
import images from '../../../assets/moda'
import 'animate.css'
import {NavLink} from "react-router-dom";

const Company = () => {
    return (
        <div className={classes.Company}>
            <div className={classes.Image}>
                <NavLink to={"/companies/products"}>
                <img
                    style={{width: '100%'}}
                    src={images[Math.floor(Math.random() * images.length)]}
                    alt=""/>
                </NavLink>
            </div>
            <div className={classes.Description}>
                <ul>
                    <li>
                        <div className="animated fadeInRight">
                            fotografia produktowa
                        </div>
                    </li>
                    <li>
                        <div className="animated fadeInRight delay-1s">
                            fotografia modowa
                        </div>
                    </li>
                    <li>
                        <div className="animated fadeInRight delay-2s">
                            fotografia reklamowa
                        </div>
                    </li>
                    <li>
                        <div className="animated fadeInRight delay-3s">
                            fotografia reportażowa z spotkań, targów, festynów, koncertów...
                        </div>
                    </li>
                </ul>
            </div>
            <div className={classes.UnderDescription}>
                <h4>
                    Nie od dziś wiadomo, że zdjęcia odzieży na osobie powodują większą sprzedaż.​
                </h4>
                <div>
                    Proponujemy:

                    Sesja zdjęciowa LOOKBOOK – jest to seria zdjęć prezentująca na modelce lub modelu poszczególne
                    produkty, w taki sposób, by klient mógł dokładnie zobaczyć wszystkie walory danej rzeczy,
                    również w szerszym kontekście, całości stylizacji. Zwykle wykonywana jest w studio
                    fotograficznym, ale również plenerze.

                    Zdjęcia odzieży do sklepu internetowego na białym tle – są to zdjęcia prezentujące stricte
                    sprzedawany produkt, w taki sposób, by wszelkie dodatki nie zakłócały jasnego odbioru, który
                    produkt jest przedmiotem sprzedaży.

                </div>
            </div>
        </div>
    );
};

export default Company;