import React, {useState} from 'react';
import {NavLink} from "react-router-dom";
import classes from "./NavBar.module.scss"
import Media from "react-media";

const NavBar = () => {
    const [menus, setMenus] = useState([false, false, false, false]);
    const [menusCancelTask, setMenusCancelTask] = useState(null as any);

    const handleMouseEnterMenu = (index: number) => {
        if (menusCancelTask !== null) {
            clearTimeout(menusCancelTask);
        }
        setMenus(menus.map((_, i) => i === index))
    };

    const handleMouseLeaveMenu = () => {
        setMenusCancelTask(setTimeout(() => {
            setMenus(menus.map(() => false));
        }, 500))
    };
    return (
        <div className={classes.navBar}>
            <div className={classes.navLinks}>
                <Media query={`(min-width: 1080px)`}>
                    <NavLink
                        to={'/home'}
                        className={classes.logo}
                    >
                        Mariusz Kusy Fotografia
                    </NavLink>
                </Media>
                <Media query={`(max-width: 1079px) and (min-width:800px)`}>
                    <NavLink
                        to={'/home'}
                        className={classes.logo}
                    >
                        M.Kusy Fotografia
                    </NavLink>
                </Media>
                <Media query={`(max-width: 799px)`}>
                    <NavLink
                        to={'/home'}
                        className={classes.logo}
                    >
                        M.Kusy Foto
                    </NavLink>
                </Media>
                <NavLink
                    activeClassName={classes.activeLink}
                    className={classes.navLink}
                    to={'/home'}
                    onMouseEnter={
                        () => handleMouseEnterMenu(0)
                    }
                    onMouseLeave={
                        () => handleMouseLeaveMenu()
                    }
                >
                    Portfolio
                    {
                        menus[0] && (
                            <div className={classes.menu}>
                                <NavLink
                                    activeClassName={classes.activeLink}
                                    className={classes.item}
                                    to={'/afterhours'}
                                >
                                    Po godzinach
                                </NavLink>
                            </div>
                        )
                    }
                </NavLink>
                <NavLink
                    activeClassName={classes.activeLink}
                    className={classes.navLink}
                    to={'/personal'}
                    onMouseEnter={
                        () => handleMouseEnterMenu(1)
                    }
                    onMouseLeave={
                        () => handleMouseLeaveMenu()
                    }
                >
                    Oferta indywidualna
                    {
                        menus[1] && (
                            <div className={classes.menu}>
                                <NavLink
                                    activeClassName={classes.activeLink}
                                    className={classes.item}
                                    to={'/personal/family'}
                                >
                                    Sesja rodzinna
                                </NavLink>
                                <NavLink
                                    activeClassName={classes.activeLink}
                                    className={classes.item}
                                    to={'/personal/outdoor'}
                                >
                                    Sesja plener komunijna
                                </NavLink>
                                <NavLink
                                    activeClassName={classes.activeLink}
                                    className={classes.item}
                                    to={'/personal/baptism'}
                                >
                                    Reportaż - chrzciny Zoi
                                </NavLink>
                                <NavLink
                                    activeClassName={classes.activeLink}
                                    className={classes.item}
                                    to={'/personal/50j'}
                                >
                                    Reportaż - 50 Jacka
                                </NavLink>
                                <NavLink
                                    activeClassName={classes.activeLink}
                                    className={classes.item}
                                    to={'/personal/25sa'}
                                >
                                    Reportaż - 25 rocznica Sławka i Agnieszki
                                </NavLink>
                                {/*<NavLink*/}
                                {/*    activeClassName={classes.activeLink}*/}
                                {/*    className={classes.item}*/}
                                {/*    to={'/personal/weddings'}*/}
                                {/*>*/}
                                {/*    Zdjęcia ślubne*/}
                                {/*</NavLink>*/}
                            </div>
                        )
                    }
                </NavLink>
                <NavLink
                    activeClassName={classes.activeLink}
                    className={classes.navLink}
                    to={'/companies'}
                    onMouseEnter={
                        () => handleMouseEnterMenu(2)
                    }
                    onMouseLeave={
                        () => handleMouseLeaveMenu()
                    }
                >
                    Oferta dla firm
                    {
                        menus[2] && (
                            <div className={classes.menu}>
                                <NavLink
                                    activeClassName={classes.activeLink}
                                    className={classes.item}
                                    to={'/companies/products'}
                                >
                                    Zdjęcia produktowe
                                </NavLink>
                            </div>
                        )
                    }
                </NavLink>
                <NavLink
                    activeClassName={classes.activeLink}
                    to={'/about'}
                    className={classes.navLink}
                >
                    O mnie
                </NavLink>
            </div>
        </div>
    );
};

export default NavBar;