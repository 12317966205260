import React from 'react';
import classes from './PersonalMobile.module.scss'
import photo from '../../../assets/personal.jpg'
import Gallery from "../Gallery/Gallery";
import familyPhotos from "../../../assets/rodzinna";
import outdoorPhotos from "../../../assets/komunia";
import partyPhotos from "../../../assets/50j";
import weddingPhotos from "../../../assets/slub";

const PersonalMobile = () => {
    return (
        <div className={classes.Personal}>
            <div className={classes.Description}>
                <div className={classes.Title}>
                    Reportaż z uroczystości<br/>
                    z których pragniecie mieć pamiątkę w postaci pięknych zdjęć
                </div>
                <div>
                    <img className={classes.Image}
                         src={photo}
                         alt="Coś poszło nie tak :("/>
                </div>
                <ul>
                    <li>
                        sesje portretowe
                    </li>
                    <li>
                        sesje rodzinne, dziecięce, małżeńskie
                    </li>
                    <li>
                        sesje w plenerze
                    </li>
                </ul>
            </div>
            <div>
                <Gallery images={{
                    "Zdjęcia rodzinne": familyPhotos,
                    "Sesje plenerowe": outdoorPhotos,
                    "Zdjęcia z przyjęć": partyPhotos,
                    "Zdjęcia ślubne": weddingPhotos
                }}/>
            </div>
        </div>
    );
};

export default PersonalMobile;