import React from 'react';
import classes from './About.module.scss'
import photo from '../../../assets/avatar.png'

const About = () => {
    return (
        <div className={classes.About}>
            <div className={classes.Title}>
                O mnie
            </div>
            <img className={classes.Image} src={photo} alt={"Coś poszło nie tak :("}/>
            <div className={classes.Description}>
                Przygody z fotografowaniem rozpocząłem już jako nastolatek, zaraził mnie tą pasją mój tata.
                <br/>
                Jako początkujący fotograf poznawałem tajniki fotografowania oraz wywoływania zdjęć
                czarno-białych, a takie aparaty jak Zenit, Praktica były szczytem marzeń w tamtym okresie.
                Początki były trudne gdyż aparaty były analogowe, trzeba było zaznajomić się z ustawieniami i znaleźć takie które były do przyjęcia w końcowym wyniku zdjęcia.
                <br/>
                Na szczęście nauka nie poszła w las i wraz z nadejściem fotografii cyfrowej łatwiej było wejść w świat ciemni cyfrowej.
                Fotografując zasadniczo trzymam się ogólnie przyjętych zasad fotografowania ale wielokrotnie wychodzę poza za te ramy.
                Przez co moje zdjęcia przedstawiają nieco inny punkt widzenia czasami niekonwencjonalny otaczającego mnie świata.
                Staram się cały czas uczyć i poprawiać swoją wiedzę o fotografii.
            </div>
        </div>
    );
};

export default About;