import React from 'react';

const Gallery = ({images, titleClass}: { images: { [_: string]: string[] }, titleClass?: string }) => {
    return (
        <>
            {
                Object.keys(images).map((title, index) => [
                        <div key={index} className={titleClass}>{title}</div>,
                        images[title].map((src, index) =>
                            <img key={index} style={{width: '100%'}} src={src} alt={"Coś poszło nie tak :("}/>
                        )

                ])
            }
        </>
    );
};

export default Gallery;