import React, {useState} from 'react';
import {NavLink, Redirect, RouteProps, withRouter} from 'react-router-dom'
import {Route} from "react-router";
import Media from "react-media";
import classes from './App.module.scss';
import homePhotos from "./assets/home";
import afterHoursPhotos from "./assets/pogodzinach";
import familyPhotos from "./assets/rodzinna";
// import weddingPhotos from "./assets/slub";
import party50jPhotos from "./assets/50j";
import party25saPhotos from "./assets/rocznica Sławka i Agnieszki";
import outdoorPhotos from "./assets/komunia";
import productPhotos from "./assets/moda";
import baptismPhotos from "./assets/chrzciny";
import welcomePhoto from "./assets/myself potrait.jpg";
import logo from "./assets/logo.png"

import Album from "./components/Album/Album";
import Personal from "./pages/Desktop/Personal/Personal";
import Company from "./pages/Desktop/Company/Company";
import About from "./pages/Desktop/About/About";
import NavBar from "./pages/Desktop/NavBar/NavBar";
import Footer from "./pages/Desktop/Footer/Footer";
import Gallery from "./pages/Mobile/Gallery/Gallery";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faBuilding, faHome, faInfo, faUser} from "@fortawesome/free-solid-svg-icons";
import AboutMobile from "./pages/Mobile/AboutMobile/AboutMobile";
import CompanyMobile from "./pages/Mobile/CompanyMobile/CompanyMobile";
import PersonalMobile from "./pages/Mobile/PersonalMobile/PersonalMobile";

let small = 600;

const App = (props: RouteProps) => {
    let [welcomeScreenOpacity, setWelcomeScreenOpacity] = useState(true)
    let [showWelcomeScreen, setShowWelcomeScreen] = useState(true)
    return (
        <>
            <Media query={`(min-width: ${small}px)`}>
                <div className={classes.page}>
                    <NavBar/>
                    {showWelcomeScreen ?
                        <div className={classes.welcome} style={{opacity: welcomeScreenOpacity ? 1 : 0}}>
                            <img src={welcomePhoto} alt="" onLoad={() => {
                                setTimeout(() => setWelcomeScreenOpacity(false), 3000)
                                setTimeout(() => setShowWelcomeScreen(false), 4000)
                            }}/>
                            <div className={classes.welcomeTitle}>
                                Mariusz Kusy
                                <div className={classes.welcomeDescription}>
                                    Fotografia
                                </div>
                            </div>
                        </div> : null
                    }
                    <div className={classes.content}>
                        <Route path={'/'}
                               render={() => (<Redirect to={'/home'}/>)} exact/>
                        <Route path={'/home'}
                               render={() => (<Album images={homePhotos}/>)}/>
                        <Route path={'/afterhours'}
                               render={() => (<Album images={afterHoursPhotos}/>)}/>
                        <Route path={'/personal'}
                               component={Personal} exact/>
                        <Route path={'/personal/family'}
                               render={() => (<Album images={familyPhotos}/>)}/>
                        <Route path={'/personal/outdoor'}
                               render={() => (<Album images={outdoorPhotos}/>)}/>
                        <Route path={'/personal/50j'}
                               render={() => (<Album images={party50jPhotos}/>)}/>
                        <Route path={'/personal/25sa'}
                               render={() => (<Album images={party25saPhotos}/>)}/>
                        <Route path={'/personal/baptism'}
                               render={() => (<Album images={baptismPhotos}/>)}/>
                        {/*<Route path={'/personal/weddings'}*/}
                        {/*       render={() => (<Album images={weddingPhotos}/>)}/>*/}
                        <Route path={'/companies'}
                               component={Company} exact/>
                        <Route path={'/companies/products'}
                               render={() => (<Album multi images={productPhotos}/>)}/>
                        <Route path={'/about'}
                               component={About}/>
                        <img className={classes.logoImg} src={logo} alt={'logo'}/>
                    </div>
                    <Footer/>
                </div>
            </Media>
            <Media query={`(max-width: ${small - 1}px)`}>
                <div className={classes.mobliePage}>
                    <NavLink to={'/home'} className={classes.title}>M.Kusy Fotografia</NavLink>
                    <Route path={'/'}
                           render={() => (<Redirect to={'/home'}/>)} exact/>
                    <Route path={'/home'}
                           render={() => (
                               <div className={classes.content}>
                                   <Gallery
                                       images={{"Portfolio": homePhotos, "Po godzinach": afterHoursPhotos}}
                                       titleClass={classes.sectionTitle}
                                   />
                               </div>)}
                    />
                    <Route path={'/personal'}
                           render={() => <div className={classes.content}><PersonalMobile/></div>}/>
                    <Route path={'/companies'}
                           render={() => <div className={classes.content}><CompanyMobile/></div>} exact/>
                    <Route path={'/companies/products'}
                           render={() => <div className={classes.content}><Album multi images={productPhotos}/></div>}/>
                    <Route path={'/about'}
                           render={() => <div className={classes.content}><AboutMobile/></div>}/>
                    <div className={classes.navigation}>
                        <NavLink to={"/home"} className={classes.button}>
                            <FontAwesomeIcon icon={faHome}/>
                        </NavLink>
                        <NavLink to={"/personal"} className={classes.button}>
                            <FontAwesomeIcon icon={faUser}/>
                        </NavLink>
                        <NavLink to={"/companies"} className={classes.button}>
                            <FontAwesomeIcon icon={faBuilding}/>
                        </NavLink>
                        <NavLink to={"/about"} className={classes.button}>
                            <FontAwesomeIcon icon={faInfo}/>
                        </NavLink>
                    </div>
                </div>
            </Media>
        </>
    );
};

export default withRouter(App);