import React from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {fa500px, faFacebook, faInstagram} from "@fortawesome/free-brands-svg-icons";

import classes from './Footer.module.scss'

const Footer = () => {
    return (
        <div className={classes.footer}>
            <a href="https://500px.com/kusy_foto">
                <FontAwesomeIcon
                    className={`${classes.button} px animated fadeInDown`}
                    style={{
                        color: '#02adea'
                    }}
                    size={"2x"}
                    icon={fa500px}/>
            </a>
            <a href="https://www.facebook.com/inny.widzenia">
                <FontAwesomeIcon
                    className={`${classes.button} fb animated fadeInDown`}
                    style={{
                        color: '#3b5998'
                    }}
                    size={"2x"}
                    icon={faFacebook}/>
            </a>
            <a href="https://www.instagram.com/innypunktwidzenia">
                <FontAwesomeIcon
                    className={`${classes.button} insta animated fadeInDown`}
                    style={{
                        color: '#3f729b'
                    }}
                    size={"2x"}
                    icon={faInstagram}/>
            </a>
            <a href={"tel:+48796696828"} className="animated fadeInDown">tel: +48 796-696-828</a>
            <a href={"mailto:mariusz@kusy.net"} className="animated fadeInDown">email: mariusz@kusy.net</a>
            <div className="animated fadeInDown">© 2020 by Rafał Kusy</div>
        </div>
    );
};

export default Footer;