import React from 'react';
import Swiper from 'react-id-swiper';
import 'swiper/css/swiper.css'
import classes from './Album.module.scss'

interface OwnProps {
    images: string[]
    multi?: boolean
}

const Album = ({images, multi}: OwnProps) => {

    return (
        <div
            className={multi ? classes.MultiAlbum : classes.Album}
            onContextMenu={(e) => e.preventDefault()}
        >
            <Swiper
                autoplay={{
                    delay: 4000
                }}
                navigation={{
                    nextEl: '.swiper-button-next',
                    prevEl: '.swiper-button-prev'
                }}
                slideNextClass={classes.blur}
                slidePrevClass={classes.blur}
                spaceBetween={0}
                centeredSlides={true}
                slidesPerView={"auto"}
                loop={true}
                slideActiveClass={classes.imageActive}
            >
                {
                    images.map((image, index) =>
                        <div key={index} className={classes.image}>
                            <img src={image} alt={''}/>
                        </div>)
                }

            </Swiper>
        </div>
    );
};

export default Album;